import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
} from "@/components/ui/sidebar";
import React from "react";
import { GroupMenu, ItemProps } from "@/components/sidebar/sidebar-utils";
import { useSessionData } from "@/stores/SessionDataContext";
import { Link } from "react-router-dom";
import {
  BookUser,
  Earth,
  PlaneTakeoff,
  TicketsPlane,
  TowerControl,
} from "lucide-react";
import { useReportContext } from "@/stores/ReportContext";
import { useAirport } from "@/stores/AirportContext";

const DATA_PREFIX = "/data";

function AirportSection() {
  const { terminals } = useSessionData();
  const { hasCustoms, hasBaggage } = useAirport();
  const URL_PREFIX = `${DATA_PREFIX}/airport`;
  const items: ItemProps[] = [
    {
      url: `/`,
      title: "Airport Overview",
      icon: TowerControl,
    },
    {
      url: `${URL_PREFIX}/pax`,
      title: "Passenger Overview",
      icon: BookUser,
    },
    {
      title: "Terminal Overview",
      url: `${URL_PREFIX}/terminals`,
      icon: TicketsPlane,
    },
  ];

  if (hasCustoms || hasBaggage) {
    items.push({
      title: "Customs Overview",
      url: `${URL_PREFIX}/intl`,
      icon: Earth,
    });
  }

  return <GroupMenu title={"Airport"} items={items} defaultOpen={true} />;
}

function TerminalSection() {
  const { terminals } = useSessionData();
  const items: ItemProps[] = terminals.map((t) => {
    const URL_PREFIX = `${DATA_PREFIX}/terminal/${t.terminal_name}`;
    return {
      subItems: [
        {
          url: `${URL_PREFIX}/flow`,
          title: "Passenger Flow",
        },
        {
          url: `${URL_PREFIX}/pain-points`,
          title: "Pain Points",
        },
        {
          url: `${URL_PREFIX}/doors`,
          title: "Curb",
        },
        {
          url: `${URL_PREFIX}/ticketing`,
          title: "Ticketing",
        },
        {
          url: `${URL_PREFIX}/checkpoints`,
          title: "Checkpoints",
        },
        {
          url: `${URL_PREFIX}/restrooms`,
          title: "Restrooms",
        },
        {
          url: `${URL_PREFIX}/gates`,
          title: "Gates",
        },
      ],
      title: "Terminal " + t.terminal_name,
      icon: PlaneTakeoff,
    };
  });
  return <GroupMenu title={"Terminals"} items={items} />;
}

function ConcourseSection() {
  const { concourses, airport_config } = useSessionData();
  if (!airport_config.has_concourse) {
    return null;
  }

  const items: ItemProps[] = concourses.map((t) => {
    const URL_PREFIX = `${DATA_PREFIX}/concourse/${t.name}`;
    return {
      subItems: [
        {
          url: `${URL_PREFIX}/gates`,
          title: "Gates",
        },
        {
          url: `${URL_PREFIX}/flow`,
          title: "Passenger Flow",
        },
        {
          url: `${URL_PREFIX}/pain-points`,
          title: "Pain Points",
        },
        // {
        //   url: `${URL_PREFIX}/flights`,
        //   title: "Restrooms",
        // },
      ],
      title: "Concourse " + t.name,
    };
  });
  return <GroupMenu title={"Concourses"} items={items} />;
}

function ExploreSection() {
  const { terminals } = useSessionData();
  const URL_PREFIX = `${DATA_PREFIX}/airport`;
  const items: ItemProps[] = [
    {
      url: `/forecast`,
      title: "Forecasts",
    },
    {
      url: `/playlist`,
      title: "Playlist",
    },
    {
      url: `/explore`,
      title: "Explore Data",
    },
    {
      url: `/flights`,
      title: "Flights",
    },
  ];
  return <GroupMenu title={"Data"} items={items} defaultOpen={true} />;
}

function ConfigurationSection() {
  const { terminals, is_admin } = useSessionData();

  if (!is_admin) {
    return null;
  }
  const URL_PREFIX = `${DATA_PREFIX}/airport`;
  const items: ItemProps[] = [
    {
      url: `/setup/rules`,
      title: "Manage Plays",
    },
    // {
    //   url: `${URL_PREFIX}/overview`,
    //   title: "Manage Users",
    // },
  ];
  return <GroupMenu title={"Configuration"} items={items} defaultOpen={true} />;
}

export function AppSidebar() {
  const { code_iata } = useSessionData();
  return (
    <Sidebar>
      <SidebarHeader>
        <Link to={"/"}>
          <div className="ml-2 mt-2 flex items-center cursor-pointer">
            <div className="text-2xl">RaidoHub</div>
            <div className="ml-4 bg-indigo-700 border-indigo-300 text-sm px-1 py-1 border rounded opacity-60">
              {code_iata}
            </div>
          </div>
        </Link>
      </SidebarHeader>
      <SidebarContent>
        <AirportSection />
        <TerminalSection />
        <ConcourseSection />
        <ExploreSection />
        <ConfigurationSection />
      </SidebarContent>
    </Sidebar>
  );
}
