import { client } from "@/queries/client";
import { useQuery, QueryClient } from "@tanstack/react-query";
import { components } from "@/lib/openapi";

export type RetailReport = components["schemas"]["RetailReport"];

async function fetchRetailReport({ report, stores, signal }: { 
  report: number; 
  stores: number[]; 
  signal?: AbortSignal 
}) {
  const { data, error } = await client
    .get()
    .POST("/api/retail/summary/{report_id}", {
      signal,
      params: {
        path: {
          report_id: report,
        },
      },
      body: {
        stores,
      },
    });
  if (data) return data as RetailReport;
  console.warn(error);
  throw new Error("Failed to fetch retail report");
}

export const getRetailReport = {
  getKey: (params: { report: number; stores: number[] }) => 
    ['retail-report', params.report, params.stores] as const,
  
  useQuery: (params: { report: number; stores: number[] }) => {
    return useQuery({
      queryKey: getRetailReport.getKey(params),
      queryFn: ({ signal }) => fetchRetailReport({ ...params, signal })
    });
  }
};

export function prefetchRetailReports(
  queryClient: QueryClient,
  reportId: number, 
  storeIds: number[]
) {
  queryClient.prefetchQuery({
    queryKey: getRetailReport.getKey({ report: reportId, stores: [] }),
    queryFn: () => fetchRetailReport({ report: reportId, stores: [] })
  });

  storeIds.forEach(storeId => {
    queryClient.prefetchQuery({
      queryKey: getRetailReport.getKey({ report: reportId, stores: [storeId] }),
      queryFn: () => fetchRetailReport({ report: reportId, stores: [storeId] })
    });
  });
}
