import React, { useEffect, useState } from "react";
import { MetricDisplay } from "@/components/retail/RetailViz";
import { getRetailReport, RetailReport } from "@/queries/retail";
import { useReportContext } from "@/stores/ReportContext";
import { ExploreContext, ZoneData } from "@/components/explore/ExploreContext";
import { trimZones } from "@/components/explore/ExploreView";
import { useSessionData } from "@/stores/SessionDataContext";
import { TableView } from "@/components/explore/ExploreTable";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { buildMultiAxis } from "@/viz/chart-data";

type SortConfig = {
  key: string;
  direction: 'asc' | 'desc';
};

function Card({ title, children, wrapperClassName = "", innerClassName = "" }) {
  return (
    <div
      className={`bg-slate-900 border-slate-600 border drop-shadow flex flex-col mt-4 h-90 md:h-auto ${wrapperClassName}`}
    >
      <div className="px-4 py-4 text-slate-300 border-b border-slate-700">
        {title}
      </div>
      <div className={`p-4 ${innerClassName}`}>{children}</div>
    </div>
  );
}

function convertTo15MinIntervalsAndSort(
  gates: ZoneData[] | null,
): ZoneData[] | null {
  if (!gates) return null;

  const convertedGates = gates.map((gate) => {
    const newValues: number[] = [];
    const newNotes: (string | null)[] = [];

    for (let i = 0; i < gate.values.length; i += 3) {
      if (i + 2 < gate.values.length) {
        // Full 30-minute block
        newValues.push(gate.values[i]);
        newValues.push(
          Math.round((gate.values[i + 1] + gate.values[i + 2]) / 2),
        );

        newNotes.push(gate.notes[i]);
        newNotes.push(gate.notes[i + 1] || gate.notes[i + 2] || null);
      } else if (i + 1 < gate.values.length) {
        // Last incomplete block (20 minutes)
        newValues.push(gate.values[i]);
        newValues.push(Math.round(gate.values[i + 1] / 2));

        newNotes.push(gate.notes[i]);
        newNotes.push(gate.notes[i + 1] || null);
      } else {
        // Last single 10-minute interval
        newValues.push(gate.values[i]);
        newNotes.push(gate.notes[i]);
      }
    }

    return {
      ...gate,
      values: newValues,
      notes: newNotes,
    };
  });

  // Sort the converted gates by name
  return convertedGates.sort((a, b) => a.name.localeCompare(b.name));
}

function GateDisplay({ summary }: { summary: RetailReport }) {
  const { airport_config } = useSessionData();

  const { zones, times } = trimZones(
    convertTo15MinIntervalsAndSort(summary.gates),
    airport_config.start_hour,
    airport_config.end_hour,
    15,
  );

  return (
    <Card title={"Relevant Gates"} innerClassName={""}>
      <ExploreContext.Provider
        value={{
          zones,
          times,
          concerns: [],
        }}
      >
        <TableView />
      </ExploreContext.Provider>
    </Card>
  );
}

function ChartDisplay({ summary }: { summary: RetailReport }) {
  return (
    <Card title="Traffic Overview">
      <HighchartsReact
        highcharts={Highcharts}
        constructorType={"chart"}
        options={buildMultiAxis({
          height: 400,
          data: summary.chart_hourly_breakdown,
          xAxisTitle: "Time",
          secondaryAxisSeriesIndex: 1,
        })}
      />
    </Card>
  );
}

function MetricsGrid({ metrics }: { metrics: { name: string; value: any }[] }) {
  return (
    <div className="grid grid-cols-3 gap-4 mt-4">
      {metrics.map((metric, index) => (
        <div key={index} className="bg-slate-900 border border-slate-600 p-4">
          <div className="text-amber-500 text uppercase mb-2 text-center">
            {metric.name}
          </div>
          <div className="text-white text-3xl text-center">
            {metric.value?.toLocaleString()}
          </div>
        </div>
      ))}
    </div>
  );
}

const sortData = (data: any[], sortConfig: SortConfig | null) => {
  if (!sortConfig) return data;

  return [...data].sort((a, b) => {
    const aValue = a[sortConfig.key];
    const bValue = b[sortConfig.key];

    if (aValue === null) return 1;
    if (bValue === null) return -1;

    if (aValue < bValue) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
};

const SingleStoreReport: React.FC<{ summary: RetailReport }> = ({
  summary,
}) => {
  const isRenderable = (value: any): boolean => {
    return value !== null && value !== undefined;
  };

  function displayStatus(status) {
    // Define a mapping of statuses to Tailwind CSS classes and text
    const statusMap = {
      OnTime: { color: "", text: "On Time" },
      Early: { color: "text-green-800", text: "Early" },
      Delayed: { color: "text-red-800", text: "Delayed" },
    };

    // Get the status configuration or use the default
    const { color, text } = statusMap[status] || { color: "", text: status };

    // Return a React node
    return <span className={color}>{text}</span>;
  }

  const { code_iata, restrict_store, stores } = useSessionData();
  const { reportId, report, formattedDate } = useReportContext();

  // Get the store name if restrict_store is enabled
  const storeName = restrict_store ? 
    stores.find(s => s.key === restrict_store)?.name : null;

  // Create a single array of metrics in the desired order
  const metrics = [
    {
      name: summary.primary_terminal
        ? `Total Flights @ Terminal ${summary.primary_terminal}`
        : `Total Flights @ ${code_iata}`,
      value: summary.total_flights
    },
    {
      name: summary.primary_terminal
        ? `Total Passengers @ Terminal ${summary.primary_terminal}`
        : `Total Passengers @ ${code_iata}`,
      value: summary.total_pax
    },
    {
      name: "Flights in Service Area",
      value: summary.service_flights
    },
    {
      name: "Remaining Departures",
      value: summary.remain_dep_flights
    },
    {
      name: "Remaining DEP Passengers",
      value: summary.remaining_pax_dep
    },
    {
      name: "Total Passengers in Service Area",
      value: summary.remain_pax
    },
    {
      name: "Flights Next Hour",
      value: summary.hour_flights
    },
    {
      name: "Pax Next Hour",
      value: summary.hour_pax
    },
    {
      name: "Current Pax in Service Area",
      value: summary.current_pax
    }
  ].filter(metric => metric.value !== null && metric.value !== undefined);

  const [sortConfig, setSortConfig] = useState<SortConfig | null>(null);

  const handleSort = (key: string) => {
    setSortConfig((currentSort) => {
      if (!currentSort || currentSort.key !== key) {
        return { key, direction: 'asc' };
      }
      if (currentSort.direction === 'asc') {
        return { key, direction: 'desc' };
      }
      return null;
    });
  };

  return (
    <div className="w-full">
      {/* {restrict_store && (
        <div className="text-center text-3xl text-indigo-400 mt-4 mb-6">
          [{code_iata}] {storeName} Overview for {formattedDate}
        </div>
      )} */}

      <div className="w-full">
        <Card
          title="Last Flights of the Day"
          wrapperClassName="w-full h-full !mt-0"
        >
          <table className="flight-table !mt-0 w-full">
            <thead>
              <tr>
                <th onClick={() => handleSort('gate_name')} className="cursor-pointer hover:bg-slate-800">
                  Gate {sortConfig?.key === 'gate_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('flight_name')} className="cursor-pointer hover:bg-slate-800">
                  Flight {sortConfig?.key === 'flight_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('sch_dep_time')} className="cursor-pointer hover:bg-slate-800">
                  Scheduled {sortConfig?.key === 'sch_dep_time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('dep_time')} className="cursor-pointer hover:bg-slate-800">
                  Estimated {sortConfig?.key === 'dep_time' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSort('status')} className="cursor-pointer hover:bg-slate-800">
                  Status {sortConfig?.key === 'status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortData(summary.takeoffs, sortConfig).map((flight) => (
                <tr key={flight.gate_name}>
                  <td>
                    {flight.gate_guess_flag ? (
                      <span className="opacity-70">*{flight.gate_name}</span>
                    ) : (
                      <span>{flight.gate_name}</span>
                    )}
                  </td>
                  <td>{flight.flight_name}</td>
                  <td>{flight.sch_dep_time}</td>
                  <td>{flight.dep_time}</td>
                  <td>{displayStatus(flight.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {summary.takeoffs.some((flight) => flight.gate_guess_flag) && (
            <div className="opacity-50 font-mono text-xs mt-4">
              Gates marked with "*" show temporary assignments based on
              historical data. These will be updated once final gates are
              confirmed.
            </div>
          )}
        </Card>
      </div>

      <MetricsGrid metrics={metrics} />

      <div className="w-full highcharts-dark">
        <ChartDisplay summary={summary} />
      </div>
      {summary.gates && <GateDisplay summary={summary} />}
    </div>
  );
};

const AllStoresReport: React.FC<{ summary: RetailReport }> = ({ summary }) => {
  const isRenderable = (value: any): boolean => {
    return value !== null && value !== undefined;
  };

  const { code_iata, brand } = useSessionData();
  const { reportId, report, formattedDate } = useReportContext();

  // Create a single array of metrics in the desired order
  const metrics = [
    {
      name: `Total Flights @ ${code_iata}`,
      value: summary.total_flights
    },
    {
      name: `Total Passengers @ ${code_iata}`,
      value: summary.total_pax
    },
    {
      name: "Flights in Service Area",
      value: summary.service_flights
    },
    {
      name: "Remaining Departures",
      value: summary.remain_dep_flights
    },
    {
      name: "Remaining DEP Passengers",
      value: summary.remaining_pax_dep
    },
    {
      name: "Remaining Pax (Airport)",
      value: summary.remain_pax
    }
  ].filter(metric => metric.value !== null && metric.value !== undefined);

  return (
    <div className="w-full">
      <div className="text-center text-3xl text-indigo-400 mt-4 mb-6">
        {code_iata} Overview for {formattedDate}
      </div>

      <MetricsGrid metrics={metrics} />

      <div className="w-full highcharts-dark">
        <ChartDisplay summary={summary} />
      </div>
    </div>
  );
};

function RetailSummary({ stores = [] }: { stores: number[] }) {
  const { reportId } = useReportContext();
  const { restrict_store } = useSessionData();
  const singleStore = !!restrict_store;
  const { data: summary } = getRetailReport.useQuery({
    report: Number(reportId),
    stores
  });

  useEffect(() => {
    if (summary) {
      const storeNames =
        stores.length === 1 ? `Store ${stores[0]}` : "All Stores";
      document.title = `Retail Summary | ${storeNames}`;
    }
  }, [summary, stores]);

  if (!summary) {
    return null;
  }

  if (stores.length === 1 || singleStore) {
    return <SingleStoreReport summary={summary} />;
  } else {
    return <AllStoresReport summary={summary} />;
  }
}

export default RetailSummary;
