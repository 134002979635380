import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LoginPage from "@/pages/LoginPage";
import ProtectedRoute from "@/pages/ProtectedRoute";
import HomePage from "@/pages/HomePage";
import React, { useState } from "react";
import AppLayout from "@/pages/layouts/AppLayout";
import { ExplorePage } from "@/pages/Explore";
import SessionDataProvider, {
  useSessionData,
} from "@/stores/SessionDataContext";
import { FlightsPage } from "@/pages/Flights";
import ManageRulesPage from "@/pages/setup/RulesPage";
import ResetPage from "@/pages/ResetPage";
import RetailHome from "@/pages/retail/RetailHome";
import RetailLayout from "@/pages/layouts/RetailLayout";
import ForgotPasswordPage from "@/pages/auth/ForgotPassword";
import NewPassword from "@/pages/auth/NewPassword";
import DataRoutes from "@/pages/data/DataRoutes";
import { FullPlaylistPage } from "@/pages/explore/AllPlaysPlage";
import { AirportProvider } from "@/stores/AirportContext";
import {ForecastPage, RetailForecastPage} from "@/pages/forecast/ForecastPage";

function AirportModeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<AppLayout />}>
        <Route index element={<HomePage />} />
        <Route path="explore" element={<ExplorePage />} />
        <Route path="flights" element={<FlightsPage />} />
        <Route path="playlist" element={<FullPlaylistPage />} />
        <Route path="data/*" element={<DataRoutes />}></Route>
      </Route>
      <Route path="/forecast" element={<AppLayout />}>
        <Route index element={<ForecastPage />} />
      </Route>
      <Route path="/setup" element={<AppLayout />}>
        <Route path="rules" element={<ManageRulesPage />} />
      </Route>
    </Routes>
  );
}

function BrandModeRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RetailLayout />}>
        <Route index element={<RetailHome />}></Route>
      </Route>
      <Route path="/store/:store" element={<RetailLayout />}>
        <Route index element={<RetailHome />} />
      </Route>
      <Route path="/forecast" element={<RetailLayout />}>
        <Route index element={<RetailForecastPage/>} />
      </Route>
    </Routes>
  );
}
function AppRoutes() {
  const { isBrand, isAirport } = useSessionData();

  if (isBrand) {
    return <BrandModeRoutes />;
  }

  if (isAirport) {
    return <AirportModeRoutes />;
  }

  return null; // Return null if neither condition is met
}

function App() {
  const [reactQueryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={reactQueryClient}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset" element={<ResetPage />} />
          <Route path="/auth/forgot" element={<ForgotPasswordPage />} />
          <Route path="/auth/new-password" element={<NewPassword />} />
          <Route
            element={
              <SessionDataProvider>
                <AirportProvider>
                  <ProtectedRoute />
                </AirportProvider>
              </SessionDataProvider>
            }
          >
            <Route path="/*" element={<AppRoutes />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
