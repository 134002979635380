import { usePage } from "@/components/page/PageContext";
import React, { useEffect } from "react";
import { useReportContext } from "@/stores/ReportContext";
import {
  useTerminal,
  useTerminalId,
} from "@/pages/data/terminal/terminal-utils";
import {
  Card,
  ExploreDisplay,
  SingleSeriesChart,
  WaitTimeChart,
} from "@/pages/data/DataChart";
import { useSessionData } from "@/stores/SessionDataContext";
import { getAggregateData, getWaitTimeData, ZoneQuery } from "@/queries/report";
import { AirportElement } from "@/lib/types";
import { TrafficChart } from "@/pages/data/terminal/TerminalCharts";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export function TerminalGatesPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const terminalId = useTerminalId();
  const [direction, setDirection] = React.useState<"dep" | "arr">("dep");
  const [passengerType, setPassengerType] = React.useState<
    "direct" | "connecting" | "both"
  >("both");

  useEffect(() => {
    setTitles(["Gates Overview"]);
  }, []);

  if (!terminalId) {
    return null;
  }

  return (
    <div className="w-full grid grid-cols-4">
      <div className="col-span-4 h-90 ">
        <Card
          title="Gate - Passenger Density"
          innerClassName="max-w-full"
          actions={
            <div className="flex items-center gap-4">
              <div className="flex items-center gap-2">
                <label className="text-sm text-muted-foreground">
                  Direction
                </label>
                <Select
                  value={direction}
                  onValueChange={(value) =>
                    setDirection(value as "dep" | "arr")
                  }
                >
                  <SelectTrigger className="w-[180px] text-sm">
                    <SelectValue placeholder="Select direction" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="dep">Departure</SelectItem>
                    <SelectItem value="arr">Arrival</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex items-center gap-2">
                <label className="text-sm text-muted-foreground">
                  Passenger Type
                </label>
                <Select
                  value={passengerType}
                  onValueChange={(value) =>
                    setPassengerType(value as "direct" | "connecting" | "both")
                  }
                >
                  <SelectTrigger className="w-[180px] text-sm">
                    <SelectValue placeholder="Select passenger type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="direct">Direct</SelectItem>
                    <SelectItem value="connecting">Connecting</SelectItem>
                    <SelectItem value="both">Both</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          }
        >
          <ExploreDisplay
            height={850}
            query={{
              terminal_id: terminalId.toString(),
              measure: "pax",
              direction: direction,
              type: "gate",
              pax_type: passengerType === "both" ? null : passengerType,
            }}
          />
        </Card>
      </div>
    </div>
  );
}

function CheckpointInfo({ checkpoint }: { checkpoint: AirportElement }) {
  const { reportId } = useReportContext();

  const { data: trafficData, isSuccess } = getAggregateData(
    [{ query: { checkpoint_id: checkpoint.id } }],
    reportId,
    true,
  );

  if (!isSuccess || !trafficData) return null;

  // Check if all values are 0
  const allZeros = trafficData[0].values.every((v) => v === 0);
  if (allZeros) return null;

  return (
    <div className="w-full mt-2">
      <div className="mb-2 text-lg border-b border-slate-600 w-full py-4 mx-2">
        Checkpoint {checkpoint.name}
      </div>
      <div className="w-full h-90 flex">
        <div className="w-full h-90 p-2">
          <Card title="Passenger Volume">
            <TrafficChart query={{ checkpoint_id: checkpoint.id }} />
          </Card>
        </div>
        {/*<div className="w-1/2 h-90 p-2">*/}
        {/*  <WaitTimeChart config={{ checkpoint_id: checkpoint.id }} />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export function TerminalCheckpointsPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const terminal = useTerminal();

  useEffect(() => {
    setTitles(["Checkpoints Overview"]);
  }, []);

  if (!terminal) {
    return null;
  }

  return (
    <div>
      <Card title="Checkpoint - Passenger Density">
        <ExploreDisplay
          query={{
            type: "checkpoint",
            terminal_id: terminal.id.toString(),
            measure: "pax",
          }}
        />
      </Card>
      
      {terminal.checkpoints.map((c) => {
        return <CheckpointInfo checkpoint={c} key={c.id} />;
      })}
    </div>
  );
}

function TicketingInfo({ ticketing }: { ticketing: AirportElement }) {
  const { reportId } = useReportContext();

  const { data: trafficData, isSuccess: trafficSuccess } = getAggregateData(
    [
      {
        query: {
          ticketing_id: ticketing.id,
        },
      },
    ],
    reportId,
    true,
  );

  // Check if all values are 0
  const allZeros =
    trafficSuccess && trafficData[0].values.every((v) => v === 0);
  if (allZeros) return null;

  return (
    <div className="w-full mt-2">
      <div className="mb-2 text-lg border-b border-slate-600 w-full py-4 mx-2">
        Ticketing Desk {ticketing.name}
      </div>
      <div className="w-full h-90 flex">
        <div className="w-full h-90 p-2">
          <Card title="Passenger Count">
            {trafficSuccess && (
              <SingleSeriesChart
                type={"column"}
                values={trafficData[0].values}
                labels={trafficData[0].labels}
                xAxisTitle={"Time"}
                yAxisTitle={"Passengers"}
              />
            )}
          </Card>
        </div>
        {/*<div className="w-1/2 h-90 p-2">*/}
        {/*  <WaitTimeChart*/}
        {/*    config={{*/}
        {/*      ticketing_id: ticketing.id,*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
    </div>
  );
}

export function TerminalTicketingPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const terminal = useTerminal();

  useEffect(() => {
    setTitles(["Ticketing Desks Overview"]);
  }, []);

  return (
    <div>
      <Card title="Ticketing Desk Passenger Density">
        <ExploreDisplay
          query={{
            type: "ticketing",
            terminal_id: terminal.id.toString(),
            measure: "pax",
          }}
        />
      </Card>
      {terminal.ticketings.map((c) => {
        return <TicketingInfo ticketing={c} key={c.id} />;
      })}
    </div>
  );
}

function RestroomInfo({ restroom }: { restroom: AirportElement }) {
  const { reportId } = useReportContext();
  const { data: trafficData, isSuccess } = getAggregateData(
    [{ query: { restroom_id: restroom.id } }],
    reportId,
    true,
  );

  if (!isSuccess || !trafficData) return null;

  // Check if all values are 0
  const allZeros = trafficData[0].values.every((v) => v === 0);
  if (allZeros) return null;
  return (
    <Card title={"Restroom " + restroom.name}>
      <TrafficChart query={{ restroom_id: restroom.id }} />
    </Card>
  );
}

export function TerminalRestroomPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const terminal = useTerminal();

  useEffect(() => {
    setTitles(["Restrooms Overview"]);
  }, []);

  return (
    <div>
      <Card title="Restroom Passenger Density">
        <ExploreDisplay
          query={{
            type: "restroom",
            terminal_id: "" + terminal.id,
            measure: "pax",
          }}
        />
      </Card>
      <div className="grid grid-cols-2 gap-4 mt-4">
        {terminal.restrooms.map((r) => (
          <RestroomInfo key={r.id} restroom={r} />
        ))}
      </div>
    </div>
  );
}

function DoorInfo({ door }: { door: AirportElement }) {
  const { reportId } = useReportContext();
  const { data: trafficData, isSuccess } = getAggregateData(
    [{ query: { door_id: door.id } }],
    reportId,
    true,
  );

  if (!isSuccess || !trafficData) return null;

  // Check if all values are 0
  const allZeros = trafficData[0].values.every((v) => v === 0);
  if (allZeros) return null;

  return (
    <Card title={"Door " + door.name}>
      <TrafficChart query={{ door_id: door.id }} />
    </Card>
  );
}

export function TerminalDoorPage() {
  const { setTitles } = usePage();
  const { reportId } = useReportContext();
  const terminal = useTerminal();

  useEffect(() => {
    setTitles(["Curb Overview"]);
  }, []);

  return (
    <div>
      <Card title="Curb - Passenger Density">
        <ExploreDisplay
          query={{
            type: "door",
            terminal_id: "" + terminal.id,
            measure: "pax",
          }}
        />
      </Card>
      <div className="grid grid-cols-2 gap-4 mt-4">
        {terminal.doors.map((d) => (
          <DoorInfo key={d.id} door={d} />
        ))}
      </div>
    </div>
  );
}
