import { getAggregateData, ZoneQuery } from "@/queries/report";
import { useReportContext } from "@/stores/ReportContext";
import {
  Card,
  MultiSeriesChart,
  SingleSeriesChart,
} from "@/pages/data/DataChart";
import React from "react";

export function TrafficChart({ query }: { query: ZoneQuery }) {
  const { reportId } = useReportContext();

  const { data: trafficData, isSuccess: trafficSuccess } = getAggregateData(
    [
      {
        query,
      },
    ],
    reportId,
    true,
  );

  if (!trafficSuccess) {
    return null;
  }

  // Check if all values are 0
  const allZeros = trafficData[0].values.every((v) => v === 0);
  if (allZeros) return null;

  return (
    <SingleSeriesChart
      type={"column"}
      values={trafficData[0].values}
      labels={trafficData[0].labels}
      xAxisTitle={"Time"}
      yAxisTitle={"Passengers"}
    />
  );
}

export function ChartIntlVsDomestic({
  query,
  title,
}: {
  query: ZoneQuery;
  title: string;
}) {
  const { reportId } = useReportContext();
  const domQuery: ZoneQuery = {
    ...query,
    flight_type: "domestic",
  };

  const intlQuery: ZoneQuery = {
    ...query,
    flight_type: "international",
  };

  const { data, isLoading, isSuccess } = getAggregateData(
    [
      {
        query: domQuery,
      },
      {
        query: intlQuery,
      },
    ],
    reportId,
  );

  return (
    <div className={"p-2"}>
      {" "}
      <Card title={title}>
        {isSuccess && (
          <MultiSeriesChart
            chartType={"column"}
            series={[
              {
                name: "Domestic",
                values: data[0].values,
              },
              {
                name: "International",
                values: data[1].values,
              },
            ]}
            labels={data[0].labels}
            xAxisTitle={"Time"}
            yAxisTitle={"Passengers"}
            stacked={true}
          />
        )}
      </Card>
    </div>
  );
}
